import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import darkSquareDesign from '../../assets/designElements/darkSquareDesign2x.svg';
import Button from '../button/Button';
import fringerpressicon from './assets/fingerpressIcon.png';
import './homeTeam.css';

export default function HomeTeam({ frontpageData }) {
    const [team, setTeam] = useState([frontpageData]);
    const [isHovering, setIsHovering] = useState(false);
    const [hoveringIndex, setHoveringIndex] = useState(0);

    const handleMouseOver = (index) => {
        setIsHovering(true);
        setHoveringIndex(index);
    }

    const handleMouseOut = () => {
        setIsHovering(false);
    }

    return (
        <section id='homeTeam'>
            {team.map((teamData, index) => {
                const { teamHeading, teamText, teamBtnOneText, teamBtnOneFile, teamBtnTwoText, teamBtnTwoFile, teamCategories } = teamData;
                return (
                    <section key={index} className="homeTeamContainer">
                        <section className="homeTeamFlexWrapper">
                            <article className='homeTeamTextContainer'>
                                <img className='homeTeamSquareDesign' src={darkSquareDesign} alt="Design element" />
                                <h2 className="homeTeamHeading">{teamHeading}</h2>
                                <div className="homeTeamText" dangerouslySetInnerHTML={{__html: teamText}}></div>
                                <div className="homeTeamBtnContainer">
                                    <a href={teamBtnOneFile} className="homeTeamProgramBtn btn solid roundCorners px14" target='_blank' download='Program' rel="noreferrer">{teamBtnOneText}</a>
                                    <a href={teamBtnTwoFile} className="homeTeamPriceBtn btn trans roundCorners px14" target='_blank' download='Program' rel="noreferrer">{teamBtnTwoText}</a>
                                </div>
                            </article>
                            <section className="homeTeamCatContainer">
                                {teamCategories.map((singleTeamCat, index) => {
                                    const { teamCatVideo, teamCatHeading, teamCatLink } =  singleTeamCat;
                                    return (
                                        <section key={index} className='homeTeamCatCardContainer' 
                                            onMouseOver={() => handleMouseOver(index)}
                                            onMouseOut={() => handleMouseOut()}
                                        >
                                            <img className={isHovering && hoveringIndex === index ? 'homeTeamCatCardPulsingIcon hide' : 'homeTeamCatCardPulsingIcon'} src={fringerpressicon} alt="pointer ikon" />
                                            <video src={teamCatVideo} muted playsInline loop className='homeTeamCatCardVideo'
                                                onMouseOver={event => event.target.play()}
                                                onMouseOut={event => event.target.pause()}
                                            ></video>
                                            <div className="homeTeamCatCardTextContainer">
                                                <h3 className="homeTeamCatCardHeading">{teamCatHeading}</h3>
                                                <Link to={`holdoversigt/${teamCatLink}`} className="homeTeamCatCardBtn btn solid roundCorners px14">Læs mere</Link>
                                            </div>
                                        </section>
                                    )
                                })}
                            </section>
                        </section>
                    </section>
                )
            })}
        </section>
    )
}
