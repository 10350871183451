import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import DancelistCategoryOverview from './components/dancelistCategory/DancelistCategoryOverview';
import Loading from './components/loading/Loading';
import Navbar from './components/navbar/Navbar';
import Signup from './components/signup/Signup';
import About from "./pages/About";
import Calender from "./pages/Calender";
import Contact from "./pages/Contact";
import Dancelist from "./pages/Dancelist";
import Gallery from "./pages/Gallery";
import Home from "./pages/Home";

import ScrollToTop from './components/scrollToTop/ScrollToTop';

import './index.css';

const baseURL = 'http://jdcms.juliussen-dans.dk/wp-json/wp/v2/';

const frontpageUrl = `${baseURL}frontpage`;
const aboutUrl = `${baseURL}om`;
const calenderUrl = `${baseURL}kalender`;
const contactUrl = `${baseURL}kontakt`;
const dancelistCategoriesUrl = `${baseURL}kategorier`;
const footerUrl = `${baseURL}footer`;
const galleryUrl = `${baseURL}gallery`;
const signupUrl = `${baseURL}signup`;


const fetchURLs = [frontpageUrl, aboutUrl, calenderUrl, contactUrl, dancelistCategoriesUrl, footerUrl, galleryUrl, signupUrl];

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [frontpage, setFrontpage] = useState([]);
  const [footer, setFooter] = useState([]);
  const [about, setAbout] = useState([]);
  const [calender, setCalender] = useState([]);
  const [contact, setContact] = useState([]);
  const [gallery, setGallery] = useState([]);

  const [pardans, setPardans] = useState([]);
  const [dancefitness, setDancefitness] = useState([]);
  const [discoHipHop, setdiscoHipHop] = useState([]);
  const [mgpBorn, setmgpBorn] = useState([]);

  const [signup, setSignup] = useState([]);
  
  const sortData = (list) => {
    list.forEach((item, index) => {
      const title = item.title.rendered;
      if(title === 'Disko og Hip Hop') {
          setdiscoHipHop(item.acf);
      }
      if(title === 'MGP/Børn') {
          setmgpBorn(item.acf);
      }
      if(title === 'Dancefitness') {
          setDancefitness(item.acf);
      }
      if(title === 'Pardans') {
          setPardans(item.acf);
      }
    })
  }
  
  const fetchData = async () => {
    setIsLoading(true);
    const [frontpageData, aboutData, calenderData, contactData, dancelistCategoriesData, footerData, galleryData, signupData] = await Promise.all(
      fetchURLs.map((url) => fetch(url).then((res) => res.json()))
    );
    setFrontpage(frontpageData[0].acf);
    setAbout(aboutData[0].acf);
    setCalender(calenderData[0].acf);
    setContact(contactData[0].acf);
    // setDancelist(dancelistCategoriesData);
    setFooter(footerData[0].acf);
    setGallery(galleryData[0].acf);
    setSignup(signupData[0].acf);
    sortData(dancelistCategoriesData);

    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);


  if(isLoading) {
    return (
      <>
        <Loading loading={isLoading} color={'#FF7F50'} />
      </>
    )
  }

  return (
    <section className="App">
      <ScrollToTop />
      <section className='navContainer'>
        <Navbar />
      </section>
      <section className='mainContent'>
        <Routes>
          <Route path='/' element={<Home frontpageData={frontpage} footerData={footer} />} />
          <Route path='galleri' element={<Gallery galleryData={gallery} footerData={footer} />} />
          <Route path='holdoversigt' element={<Dancelist frontpageData={frontpage} footerData={footer} />} />
          <Route path='kalender' element={<Calender calenderData={calender} footerData={footer} />} />
          <Route path='om' element={<About aboutData={about} footerData={footer} />} />
          <Route path='kontakt' element={<Contact contactData={contact} footerData={footer} />} />
          <Route path='holdoversigt/diskohiphop' element={<DancelistCategoryOverview dancelistData={discoHipHop} footerData={footer} />} />
          <Route path='holdoversigt/pardans' element={<DancelistCategoryOverview dancelistData={pardans} footerData={footer} />} />
          <Route path='holdoversigt/mgpborn' element={<DancelistCategoryOverview dancelistData={mgpBorn} footerData={footer} />} />
          <Route path='holdoversigt/dancefitness' element={<DancelistCategoryOverview dancelistData={dancefitness} footerData={footer} />} />
          <Route path='holdoversigt/dancefitness/signup' element={<Signup signupData={signup} footerData={footer} />} />
          <Route path='holdoversigt/diskohiphop/signup' element={<Signup signupData={signup} footerData={footer} />} />
          <Route path='holdoversigt/pardans/signup' element={<Signup signupData={signup} footerData={footer} />} />
          <Route path='holdoversigt/mgpborn/signup' element={<Signup signupData={signup} footerData={footer} />} />
        </Routes>
      </section>
    </section>
  );
}

export default App;
