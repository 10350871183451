import React from 'react';
import CalenderSection from '../components/calender/CalenderSection';
import Footer from '../components/footer/Footer';

export default function Calender({ calenderData, footerData }) {
  return (
    <>
      <CalenderSection calenderData={calenderData} />
      <Footer footerData={footerData} />
    </>
  )
}
