import React, { useState } from 'react';
import Footer from '../footer/Footer';
import DancelistCategory from './DancelistCategory';

export default function DancelistCategoryOverview({ dancelistData, footerData }) {
    const [dancelistCategory, setdancelistCategory] = useState(dancelistData);
    const [footer, setFooter] = useState(footerData);

    return (
        <>
            <DancelistCategory dancelistData={dancelistCategory} />
            <Footer footerData={footer}/>
        </>
    )
}
