import React, { useState } from 'react';
import './aboutsection.css';

export default function Aboutsection({ aboutData }) {
    const [about, setAbout] = useState([aboutData]);

    return (
        <section id='about'>
            {about.map((abloutData, index) => {
                const { heading, text, picture, firstInfoHeading, firstInfoText, secoundInfoHeading, secoundInfoText, thirdInfoHeading, thirdInfoText } = abloutData;
                return (
                    <section key={index} className='aboutContainer'>
                        <article className="aboutTextContainer">
                            <h1 className="aboutHeading">{heading}</h1>
                            <div className="aboutText" dangerouslySetInnerHTML={{__html: text}}></div>
                            <section className='aboutFunfactContainer'>
                                <div className="aboutSingleFunfactContainer">
                                    <div className="aboutSingleFunfactFlexWrapper">
                                        <h3 className="aboutSingleFunfactHeading">{firstInfoHeading}</h3>
                                        <p className="aboutSingleFunfactText">{firstInfoText}</p>
                                    </div>
                                </div>
                                <div className="aboutSingleFunfactContainer">
                                    <div className="aboutSingleFunfactFlexWrapper">
                                        <h3 className="aboutSingleFunfactHeading">{secoundInfoHeading}</h3>
                                        <p className="aboutSingleFunfactText">{secoundInfoText}</p>
                                    </div>
                                </div>
                                <div className="aboutSingleFunfactContainer">
                                    <div className="aboutSingleFunfactFlexWrapper">
                                        <h3 className="aboutSingleFunfactHeading">{thirdInfoHeading}</h3>
                                        <p className="aboutSingleFunfactText">{thirdInfoText}</p>
                                    </div>
                                </div>
                            </section>
                        </article>
                        <section className="aboutMediaContainer">
                            <img src={picture} alt="Billede af Ulla og Keld" />
                        </section>
                    </section>
                )
            })}
        </section>
    )
}
