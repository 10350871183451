import React from 'react';
import Footer from '../components/footer/Footer';
import GallerySection from '../components/gallery/GallerySection';

export default function Gallery({ galleryData, footerData }) {
  return (
    <>
      <GallerySection galleryData={galleryData} />
      <Footer footerData={footerData} />
    </>
  )
}
