import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import homeCalenderDesign from '../../assets/designElements/homeCalenderDesign.svg';

import './dancelistCategory.css';

export default function DancelistCategory({ dancelistData }) {
    const [danceCategory, setDanceCategory] = useState([dancelistData]);

    return (
        <section id='danceCategoryHero'>
            {danceCategory.map((singleDanceCategory, index) => {
                const {categoryHeading, categoryText, categoryVideo, categoryTeams} = singleDanceCategory;
                return (
                    <section key={index} className='danceCategoryContainer'>
                        <section className="danceCategoryHeroContainer">
                            <article className="danceCategoryHeroTextContainer">
                                <img src={homeCalenderDesign} alt="Design element" />
                                <h1 className="danceCategoryHeroHeading"><div dangerouslySetInnerHTML={{__html: categoryHeading}}></div></h1>
                                <div className="danceCategoryHeroText" dangerouslySetInnerHTML={{__html: categoryText}}></div>
                            </article>
                            <div className="danceCategoryHeroMediaContainer">
                                <video src={categoryVideo} muted playsInline loop autoPlay></video>
                            </div>
                        </section>
                        <section className="danceCategoryMainContainer">
                            {categoryTeams.map((singleCategory, index) => {
                                const {categoryTeamHeading, categoryTeamText, categoryTeamDay, categoryTeamTime, categoryTeamBtnText, categoryShowSignupBtn } = singleCategory;

                                const signupBtn = () => {
                                    return (
                                        <Link 
                                            to={'signup'}
                                            state={categoryTeamHeading} 
                                            className='danceCategorySingleBtn btn solid'>{categoryTeamBtnText}
                                        </Link>
                                    )
                                }

                                const emptySignupBtn = () => {
                                    return (
                                        <Link className='danceCategorySingleBtnEmpty'></Link>
                                    )
                                }

                                return (
                                    <article key={index} className='danceCategorySingleContainer'>
                                        <div className="danceCategorySingleHoverContainer"></div>
                                        <article>
                                            <h3 className="danceCategorySingleHeading">{categoryTeamHeading}</h3>
                                            <div className="danceCategorySingleText" dangerouslySetInnerHTML={{__html: categoryTeamText}}></div>
                                            <div className="danceCategorySingleDayTimeContainer">
                                                <div className="danceCategorySingleDayTimeWrapper">
                                                    <p className="danceCategorySingleDayTimeText">{categoryTeamDay}</p>
                                                </div>
                                                <div className="danceCategorySingleDayTimeWrapper">
                                                    <p className="danceCategorySingleDayTimeText">{categoryTeamTime}</p>
                                                </div>
                                            </div>
                                            {categoryShowSignupBtn ? emptySignupBtn() : signupBtn()}
                                        </article>
                                    </article>
                                )
                            })}
                        </section>
                    </section>
                )
            })}
        </section>

    )
}
