import React, { useState } from 'react';
import { BiSolidQuoteAltRight } from 'react-icons/bi';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1111 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1111, min: 800 },
    items: 2,
    partialVisibilityGutter: 30
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1,
    partialVisibilityGutter: 30
  }
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div className="carousel-button-group homeReviewSlideBtnContainer">
        <div className={currentSlide === 0 ? 'disable homeReviewLeftArrowWrapper' : 'homeReviewLeftArrowWrapper'} onClick={() => previous()}>
          {/* <img src={darkSquareDesign} alt="Design element" /> */}
          <BsArrowLeft />
        </div>
        <div className="homeReviewRightArrowWrapper"  onClick={() => next()}>
          {/* <img src={darkSquareDesign} alt="Design element" /> */}
          <BsArrowRight />
        </div>
      </div>
    );
  };

export default function Slide({slideData}) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(1);
  return (
    <Carousel className="homeReviewReviewsContainer" responsive={responsive} customButtonGroup={<ButtonGroup />}
    partialVisible={true}
    renderButtonGroupOutside={true}
    arrows={false}
    swipeable={true}
    draggable={true}
    showDots={false}
    ssr={true} // means to render carousel on server-side.
    infinite={true}
    autoPlay={true}
    autoPlaySpeed={7000}
    keyBoardControl={true}
    customTransition="all 500ms linear"
    transitionDuration={1000}
    containerClass="carousel-container"
    dotListClass="custom-dot-list-style"
    itemClass="carousel-item-padding-40-px"
    >
        {slideData.map((singleReview, index) => {
            const {reviewsImage, reviewsName, reviewsTeam, reviewsText} = singleReview;
            return (
                <article key={index} className={activeSlideIndex === index ? 'homeReviewSingleReviewContainer active' : 'homeReviewSingleReviewContainer'}>
                    <BiSolidQuoteAltRight />
                    <div className="homeReviewSingleReviewTopContainer">
                        <div className="homeReviewSingleReviewImageWrapper">
                            <img src={reviewsImage} alt="Billede af anmelder" />
                        </div>
                        <article className="homeReviewSingleReviewTextWrapper">
                            <h3 className="homeReviewSingleReviewHeading">{reviewsName}</h3>
                            <p className="homeReviewSingleReviewTeam">{reviewsTeam}</p>
                        </article>
                    </div>
                    <div className="homeReviewSingleReviewText" dangerouslySetInnerHTML={{__html: reviewsText}}></div>
                </article>
            )
        })}
    </Carousel>
  )
}
