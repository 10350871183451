import React, { useState } from 'react';
import DancelistOverview from '../components/dancelistOverview/DancelistOverview';
import Footer from '../components/footer/Footer';

export default function Dancelist({ frontpageData, footerData }) {
  const [frontpage, setFrontpage] = useState(frontpageData);
  const [footer, setFooter] = useState(footerData);

  return (
    <>
      <DancelistOverview frontpageData={frontpage} />
      <Footer footerData={footer}/>
    </>
  )
}
