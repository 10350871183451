import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import darkSquareDesign from '../../assets/designElements/darkSquareDesign2x.svg';
import frontpageHeroDesign from '../../assets/designElements/frontpageHeroDesign.svg';
import orangeSquareDesign4px from '../../assets/designElements/orangeSquareDesign2x.svg';
import './homehero.css';

export default function Homehero({ frontpageData }) {
    const [hero, setHero] = useState([frontpageData]);

    return (
        <section id='homeHero'>
            {hero.map((heroData, index) => {
                const { heroHeading, heroText, heroCtaText, heroCtaLink, heroVideo } = heroData;

                return (
                    <section className='homeHeroContainer' key={index}>
                        <img src={frontpageHeroDesign} alt="design element" />
                        <section className="homeHeroFlexContainer">
                            <article className="homeHeroTextContainer">
                                <div className="homeHeroTextWrapper">
                                    <h1 className="homeHeroHeading"><div dangerouslySetInnerHTML={{__html: heroHeading}}></div></h1>
                                    <div className="homeHeroText" dangerouslySetInnerHTML={{__html: heroText}}></div>
                                    <Link to={heroCtaLink} className='homeHeroBtn btn px16 solid roundCorners' >{heroCtaText}</Link>
                                </div>
                            </article>
                            <section className="homeHeroMediaContainer">
                                <img className='homeHeroSquareDesign dark' src={darkSquareDesign} alt="Design element" />
                                <img className='homeHeroSquareDesign orange' src={orangeSquareDesign4px} alt="Design element" />
                                
                                <video src={heroVideo} playsInline muted autoPlay loop></video>
                            </section>
                        </section>
                    </section>
                )
            })}
        </section>
    )
}
