import React, { useEffect, useState } from 'react';
import Footer from '../components/footer/Footer';
import HomeCalender from '../components/homeCalender/HomeCalender';
import HomeFAQ from '../components/homeFAQ/HomeFAQ';
import HomeReview from '../components/homeReview/HomeReview';
import HomeTeam from '../components/homeTeam/HomeTeam';
import Homehero from '../components/homehero/Homehero';

export default function Home({ frontpageData, footerData }) {
  const [frontpage, setFrontpage] = useState(frontpageData);
  const [footer, setFooter] = useState(footerData);

  return (
    <>
      <Homehero frontpageData={frontpage} />
      <HomeTeam frontpageData={frontpage} />
      <HomeCalender frontpageData={frontpage} />
      <HomeFAQ frontpageData={frontpage} />
      <HomeReview frontpageData={frontpage} />
      <Footer footerData={footer} />
    </>
  )
}
