import React, { useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import LogoWhite from '../../assets/logoWhite.png';
import Hamburgermenu from './Hamburgermenu';
import './navbar.css';

export default function Navbar() {
    const [burgerNavActive, setBurgerNavActive] = useState(false);

    const handleBurgerClick = () => {
        setBurgerNavActive(current => !current);
    }

    const handleLogoClick = () => {
        setBurgerNavActive(false);
    }

    return (
        <nav id='navbar'>
            <article className="navbarContainer">
                <div className="navbarLogoContainer">
                    <Link to="/" className='navbarLogoContainer'><img src={LogoWhite} alt="Logo" onClick={() => handleLogoClick()} /> <span onClick={() => handleLogoClick()}>JULIUSSENS DANSEINSTITUT</span></Link>
                </div>
                <div className={burgerNavActive ? 'navbarLinksContainer active' : 'navbarLinksContainer'}>
                    <ul className='navbarLinksWrapper'>
                        <div>
                            <CustomLink to='/' onClick={() => handleBurgerClick()}>Forside</CustomLink>
                            <CustomLink to='/galleri' onClick={() => handleBurgerClick()}>Galleri</CustomLink>
                            <CustomLink to='/holdoversigt' onClick={() => handleBurgerClick()}>Holdoversigt</CustomLink>
                            <CustomLink to='/kalender' onClick={() => handleBurgerClick()}>Kalender</CustomLink>
                            <CustomLink to='/om' onClick={() => handleBurgerClick()}>Om os</CustomLink>
                            <CustomLink to='/kontakt' onClick={() => handleBurgerClick()}>Kontakt</CustomLink>
                        </div>
                    </ul>
                </div>
                <Hamburgermenu handleBurgerClick={handleBurgerClick} isBurgerActive={burgerNavActive} />
            </article>
        </nav>
    )
}

const CustomLink = ({ to, children, ...props }) => {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    return (
        <li className={isActive ? 'active' : ''}>
            <Link to={to} {...props}>{children}</Link>
        </li>
    )
}
