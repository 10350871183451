import React, { useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { FiMinus } from 'react-icons/fi';
import './homeFAQ.css';

export default function HomeFAQ({ frontpageData }) {
    const [faq, setFaq] = useState([frontpageData]);
    const [accordin, setAccordion] = useState(1);

  return (
    <section id="homeFaq">
        {faq.map((faqData, index) => {
            const { faqImage, faqHeading, faqText, faqBtnsText, faqBtnEmailText, faqEmail, faqPhone, faqBtnPhoneText, faqQuestonAnwser } = faqData;
            return (
                <section key={index} className="homeFaqContainer">
                    <section className="homeFaqImageContainer">
                        <img src={faqImage} alt="Faq billede" />
                    </section>
                    <article className='homeFaqTextContainer'>
                        <h2 className="homeFaqHeading"><div dangerouslySetInnerHTML={{__html: faqHeading}}></div></h2>
                        <div className="homeFaqText" dangerouslySetInnerHTML={{__html: faqText}}></div>
                        <article className="homeFaqFaqContainer">
                            {faqQuestonAnwser.map((singleQA, index) => {
                                const { faqQuestion, faqAnwser } = singleQA;

                                const showImage = (url) => {
                                    return <img src={url} alt="Ikon" />
                                }

                                return (
                                    <div key={index} className="homeFaqFaqSingleWrapper" onClick={() => setAccordion(index)}>
                                        <article className='homeFaqFaqTopContainer'>
                                            <div className="homeFaqFaqQuestion" dangerouslySetInnerHTML={{__html: faqQuestion}}></div>
                                            <div className="homeFaqFaqIconWrapper">{(index === accordin ? <FiMinus /> : <BiPlus /> )}</div>
                                        </article>
                                        <div className={`homeFaqFaqAnwerser ${(index === accordin) ? 'open' : ''}`} dangerouslySetInnerHTML={{__html: faqAnwser}}></div>
                                    </div>
                                )
                            })}
                        </article>
                        <div className="fs3BtnsText" dangerouslySetInnerHTML={{__html: faqBtnsText}}></div>
                        <div className="homeFaqBtnContainer">
                            <a href={`mailto:${faqEmail}`} className="homeFaqBtnEmail btn solid roundCorners px14">{faqBtnEmailText}</a>
                            <a href={`tel:${faqPhone}`} className="homeFaqBtnEmail btn trans roundCorners px14">{faqBtnPhoneText}</a>
                        </div>
                    </article>
                </section>
            )
        })}
    </section>
  )
}
