import React, { useState } from 'react';
import { BiSolidLeftArrow, BiSolidRightArrow } from 'react-icons/bi';
import homeCalenderDesign from '../../assets/designElements/homeCalenderDesign.svg';
import closeIcon from './assets/g1CloseIcon.png';
import './gallerySection.css';

export default function GallerySection({ galleryData }) {
    const [gallery, setGallery] = useState([galleryData]);
    const [activeSubCategoryIndex, setActiveSubCategoryIndex] = useState({categoryIndex: 0, subCategoryIndex: 0});
    const [modalImage, setModalImage] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handleCategoryToggle = (element) => {
        if(element.target.classList.contains('active')) {
            element.target.classList.remove('active');
        } else {
            element.target.classList.add('active');
        }
    }

    const handleSubCategoryClick = (catIndex, subCatIndex) => {
        setActiveSubCategoryIndex({...activeSubCategoryIndex, categoryIndex: catIndex, subCategoryIndex: subCatIndex});
    }

    const toggleModal = (modalImage, currentImageIndex, output) => {
        setModalImage(modalImage);
        setCurrentImageIndex(currentImageIndex);
    }

    const nextSlide = (list) => {
        const length = list.length;
        const currentIndex = currentImageIndex + 1 > length - 1 ? length - 1 : currentImageIndex + 1;
        setModalImage(list[currentIndex]);
        setCurrentImageIndex(currentIndex);
    }

    const prevSlide = (list) => {
        const currentIndex = currentImageIndex - 1 < 0 ? 0 : currentImageIndex - 1;
        setModalImage(list[currentIndex]);
        setCurrentImageIndex(currentIndex);
    }

    return (
        <section id="g1">
            {gallery.map((data, dataIndex) => {
                const {galleryTopHeading, galleryTopText, galleryTopImage, galleryCategories} = data;
                return (
                    <section key={dataIndex} className='g1MainContainer'>
                        <section id="g1Top">
                            <section className="g1TopContainer">
                                <article className='g1TopTextContainer'>
                                    <img src={homeCalenderDesign} alt="Design element" />
                                    <h1 className="g1TopHeading">{galleryTopHeading}</h1>
                                    <div className="g1TopText" dangerouslySetInnerHTML={{__html: galleryTopText}}></div>
                                </article>
                                <section className='g1TopMediaContainer'>
                                    <img src={galleryTopImage} alt="Galleri billede" />
                                </section>
                            </section>
                        </section>
                        <section className="g1Container">
                            <article className='g1NavigationContainer'>
                                {galleryCategories.map((singleCategory, categoryIndex) => {
                                    const {categoryHeading, subCategories} = singleCategory;
                                    return (
                                        <div key={categoryIndex} className="g1NavigationCategoryContainer">
                                            <div className="g1TopNavigationWrapper" onClick={(e) => handleCategoryToggle(e)}>
                                                <p className="g1TopNavigationHeading">{categoryHeading}</p>
                                            </div>
                                            <div className="g1TestContainer">
                                                <div className='g1SubNavigationContainer'>
                                                    {subCategories.map((singleSubCategory, subCategoryIndex) => {
                                                        const {subCategoryHeading} = singleSubCategory;
                                                        return (
                                                            <div key={subCategoryIndex} onClick={() => handleSubCategoryClick(categoryIndex, subCategoryIndex)} className={activeSubCategoryIndex.categoryIndex === categoryIndex && activeSubCategoryIndex.subCategoryIndex === subCategoryIndex ? 'g1SubNavigationWrapper active' : 'g1SubNavigationWrapper'}>
                                                                <p className="g1SubNavigationHeading">{subCategoryHeading}</p>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </article>
                            <section className="g1ImageGalleryContainer">
                                <h2 className='g1ImagesHeading'>{galleryCategories[activeSubCategoryIndex.categoryIndex].subCategories[activeSubCategoryIndex.subCategoryIndex].subCategoryHeading}</h2>
                                <section className="g1ImagesContainer">
                                    {galleryCategories[activeSubCategoryIndex.categoryIndex].subCategories[activeSubCategoryIndex.subCategoryIndex].galleryImages.map((singleSubCategoryImage, imageIndex) => {
                                        return (
                                            <div key={imageIndex} className="g1ImageWrapper" onClick={() => toggleModal(singleSubCategoryImage, imageIndex)}>
                                                <img className='g1Image' src={singleSubCategoryImage} alt="gallery billede" />
                                            </div>
                                        )
                                    })}
                                </section>
                            </section>
                            <section className={modalImage !== null ? 'g1ModalContainer active' : 'g1ModalContainer'}>
                                <img className='g1CloseModalIcon' src={closeIcon} alt="lukke ikon" onClick={() => toggleModal(null, 0)} />
                                <section className="g1ModalWrapper">
                                    <BiSolidLeftArrow className='g1ModalArrow' onClick={() => prevSlide(galleryCategories[activeSubCategoryIndex.categoryIndex].subCategories[activeSubCategoryIndex.subCategoryIndex].galleryImages)} />
                                    <img className="g1ModalImage" src={modalImage} alt="Galleri billede" />
                                    <BiSolidRightArrow className='g1ModalArrow' onClick={() => nextSlide(galleryCategories[activeSubCategoryIndex.categoryIndex].subCategories[activeSubCategoryIndex.subCategoryIndex].galleryImages)} />
                                </section>
                            </section>
                        </section>
                    </section>
                )
            })}
        </section>
    )
}
