import React from 'react';
import Contactform from '../components/contact/Contactform';
import Footer from '../components/footer/Footer';

export default function Contact({ contactData, footerData }) {
  return (
    <>
      <Contactform contactData={contactData} />
      <Footer footerData={footerData} />
    </>
  )
}
