import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import darkSquareDesign from '../../assets/designElements/darkSquareDesign2x.svg';
import fringerpressicon from './assets/fingerpressIcon.png';
import './dancelistOverview.css';

export default function DancelistOverview({ frontpageData }) {
    const [dancelistOverview, setDancelistOverview] = useState([frontpageData]);
    const [isHovering, setIsHovering] = useState(false);
    const [hoveringIndex, setHoveringIndex] = useState(0);

    const handleMouseOver = (index) => {
        setIsHovering(true);
        setHoveringIndex(index);
    }

    const handleMouseOut = () => {
        setIsHovering(false);
    }

    return (
        <section id="dancelistOverview">
            {dancelistOverview.map((dancelistOverviewData, index) => {
                const { teamCategories, teamBtnOneText, teamBtnOneFile, teamBtnTwoText, teamBtnTwoFile, dancelistOverviewHeading, dancelistOverviewText } = dancelistOverviewData;
                return (
                    <section key={index} className="dancelistOverviewContainer">
                        <article className="dancelistOverviewTextContainer">
                            <img className='homeTeamSquareDesign' src={darkSquareDesign} alt="Design element" />
                            <h1 className="dancelistOverviewHeading"><div dangerouslySetInnerHTML={{__html: dancelistOverviewHeading}}></div></h1>
                            <div className="dancelistOverviewText" dangerouslySetInnerHTML={{__html: dancelistOverviewText}}></div>
                            <div className="homeTeamBtnContainer">
                                <a href={teamBtnOneFile} className="homeTeamProgramBtn btn solid px14 roundCorners" target='_blank' download='Program' rel="noreferrer">{teamBtnOneText}</a>
                                <a href={teamBtnTwoFile} className="homeTeamPriceBtn btn trans px14 roundCorners" target='_blank' download='Pris' rel="noreferrer">{teamBtnTwoText}</a>
                            </div>
                        </article>
                        <section className="dancelistOverviewCtaContainer">
                            {teamCategories.map((singleDanceCategory, index) => {
                                const { teamCatVideo, teamCatHeading, teamCatLink } = singleDanceCategory;
                                return (
                                    <section key={index} className='homeTeamCatCardContainer dancelist' 
                                        onMouseOver={() => handleMouseOver(index)}
                                        onMouseOut={() => handleMouseOut()}
                                    >
                                        <img className={isHovering && hoveringIndex === index ? 'homeTeamCatCardPulsingIcon hide' : 'homeTeamCatCardPulsingIcon'} src={fringerpressicon} alt="pointer ikon" />
                                        <video src={teamCatVideo} muted playsInline loop className='homeTeamCatCardVideo'
                                            onMouseOver={event => event.target.play()}
                                            onMouseOut={event => event.target.pause()}
                                        ></video>
                                        <div className="homeTeamCatCardTextContainer">
                                            <h3 className="homeTeamCatCardHeading">{teamCatHeading}</h3>
                                            <Link to={teamCatLink} className="homeTeamCatCardBtn btn px14 roundCorners">Læs mere</Link>
                                        </div>
                                    </section>
                                )
                            })}
                        </section>
                    </section>
                )
            })}
        </section>
    )
}
