import React from 'react';
import Aboutsection from '../components/about/Aboutsection';
import Footer from '../components/footer/Footer';

export default function About({ aboutData, footerData }) {
  return (
    <>
      <Aboutsection aboutData={aboutData} />
      <Footer footerData={footerData} />
    </>
  )
}
