import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import homeCalenderDesign from '../../assets/designElements/homeCalenderDesign.svg';
import './homeCalender.css';

export default function HomeCalender({ frontpageData }) {
    const [calender, setCalender] = useState([frontpageData]);

    return (
        <section id='homeCalender'>
            {calender.map((calenderData, index) => {
                const { calenderShowVideo, calenderHeading, calenderText, calenderBtnText, calenderBtnLink, calenderImage, calenderVideo } = calenderData;

                const showImage = (url) => {
                    return <img src={url} alt="Billede" />
                }

                const showVideo = (url) => {
                    return <video src={url} autoPlay playsInline muted loop ></video>
                }

                return (
                    <section key={index} className='homeCalenderContainer'>
                        <section className="homeCalenderMediaContainer">
                            {calenderShowVideo ? showVideo(calenderVideo) : showImage(calenderImage)}
                        </section>
                        <section className="homeCalenderTextContainer">
                            <img src={homeCalenderDesign} alt="design element" />
                            <h2 className="homeCalenderHeading"><div dangerouslySetInnerHTML={{__html: calenderHeading}}></div></h2>
                            <div className="homeCalenderText" dangerouslySetInnerHTML={{__html: calenderText}}></div>
                            <Link to={calenderBtnLink} className="homeCalenderBtn btn solid roundCorners px16">{calenderBtnText}</Link>
                        </section>
                    </section>
                )
            })}
        </section>
    )
}
