import emailjs from '@emailjs/browser';
import React, { useEffect, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { BsCheckLg } from 'react-icons/bs';
import { GrMail } from 'react-icons/gr';
import { MdError } from 'react-icons/md';

export default function ContactUs({btnText}) {
    const formRef = useRef();
    const form = useForm();
    const { register, handleSubmit, formState, reset } = form;
    const { errors, isSubmitSuccessful } = formState;

    const [success, setSuccess] = useState(false);

    const sendEmail = () => {
      emailjs.sendForm(
        'service_a1g59rn', 
        'template_1al70fh', 
        formRef.current, 
        '-RcJ-p5Sbuzu7nWZc')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    const onSubmit = () => {
      sendEmail();
      reset();
      setSuccess(true);
    }

    useEffect(() => {
      if(isSubmitSuccessful) {
        reset();
      }
    }, [isSubmitSuccessful, reset])
    
    return (
      <form className='cf1FormWrapper' ref={formRef} onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="cf1FormItemGroupContainer">
          <div className="cfFormItemContainer">
            <div className="cfLabelErrorMsgContainer">
              <label htmlFor='firstName' >Fornavn</label>
              <p className='inputErrorMessage'>{errors.firstName ? <MdError /> : <></>}{errors.firstName?.message}</p>
            </div>
            <input type='text' id='firstName' {...register('firstName', {
              required: {
                value: true,
                message: 'Påkrævet',
              }
            })} />
          </div>
          <div className="cfFormItemContainer">
            <div className="cfLabelErrorMsgContainer">
              <label htmlFor='lastName' >Efternavn</label>
              <p className='inputErrorMessage'>{errors.lastName ? <MdError /> : <></>}{errors.lastName?.message}</p>
            </div>
            <input type='text' id='lastName' {...register('lastName', {
              required: {
                value: true,
                message: 'Påkrævet',
              }
            })} />
          </div>
        </div>
        <div className="cf1FormItemGroupContainer">
          <div className="cfFormItemContainer">
            <div className="cfLabelErrorMsgContainer">
              <label htmlFor='email' >E-mail</label>
              <p className='inputErrorMessage'>{errors.email ? <MdError /> : <></>}{errors.email?.message}</p>
            </div>
              <input type='email' id='email' {...register('email', {
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Ikke godkendt email',
                },
                required: {
                  value: true,
                  message: 'Påkrævet',
                }
              })} />
          </div>
          <div className="cfFormItemContainer">
            <div className="cfLabelErrorMsgContainer">
              <label htmlFor='phoneNumber' >Telefon</label>
              <p className='inputErrorMessage'>{errors.phoneNumber ? <MdError /> : <></>}{errors.phoneNumber?.message}</p>
            </div>
            <input type='text' id='phoneNumber' {...register('phoneNumber', {
              pattern: {
                value: /^[0-9]{8}$/,
                message: 'ikke gyldigt nummer',
              },
              required: {
                value: true,
                message: 'Påkrævet',
              }
            })} />
          </div>
        </div>
        <div className="cf1FormItemMessageContainer">
          <div className="cfFormItemContainer">
            <div className="cfLabelErrorMsgContainer">
              <label htmlFor='message' >Besked</label>
              <p className='inputErrorMessage'>{errors.message ? <MdError /> : <></>}{errors.message?.message}</p>
            </div>
            <textarea id='message' {...register('message', {
              required: {
                value: true,
                message: 'Påkrævet',
              }
            })} ></textarea>
          </div>
        </div>
        <div className="cf1FormBtnContainer">
          {success && ( <p className="cf1FormSuccesMsg"><BsCheckLg /> Din besked er sendt</p> )}
          <button className='cf1FormSubmitBtn btn px14 solid roundCorners' ><GrMail /><span>{btnText}</span></button>
        </div>
      </form>
    );
}
