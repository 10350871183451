import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

export default function Footer({ footerData }) {
    const [footer, setFooter] = useState([footerData]);
  

    return (
      <section id="f1">
        {footer.map((data, index) => {
            const {footerLogo, footerText, footerAddressHeading, footerAddress, footerMenuHeading, footerTeamHeading, footerCopyrightText, footerEmail, footerPhone} = data;
            return (
                <section key={index} className="f1Container">
                    <section className="f1TopContainer">
                        <article className="f1TopWrapper">
                            <div className="f1TopFlexWrapper">
                                <div className="f1LogoContainer">
                                  <img className='f1Logo' src={footerLogo} alt="Logo" />
                                </div>
                                <div className="f1Text" dangerouslySetInnerHTML={{__html: footerText}}></div>
                                <div className="f1BtnContainer">
                                    <a href={`mailto:${footerEmail}`} className="f1EmailBtn btn px14 roundCorners">Send en mail</a>
                                    <a href={`tel:${footerPhone}`} className="f1PhoneBtn btn px14 roundCorners">Ring til os</a>
                                </div>
                            </div>
                        </article>
                        <article className="f1TopWrapper">
                            <div className="f1TopFlexWrapper">
                                <h3 className="f1Heading">{footerAddressHeading}</h3>
                                <div className="f1Adress" dangerouslySetInnerHTML={{__html: footerAddress}}></div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d997.4729430098276!2d9.834336927774345!3d55.85831092455396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464c631da6251f13%3A0x5d8757dd6398902e!2sS%C3%B8nderbro%20Kulturhus&#39;%20Fond!5e0!3m2!1sda!2sdk!4v1686925704198!5m2!1sda!2sdk" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </article>
                        <article className="f1TopWrapper">
                            <div className="f1TopFlexWrapper">
                                <h3 className="f1Heading">{footerMenuHeading}</h3>
                                <ul className="f1MenuContainer">         
                                  <Link to='/'>Forside</Link>     
                                  <Link to='/galleri'>Galleri</Link>     
                                  <Link to='/holdoversigt'>Holdoversigt</Link>     
                                  <Link to='/kalender'>Kalender</Link>     
                                  <Link to='/om'>Om os</Link>     
                                  <Link to='/kontakt'>Kontakt</Link>     
                                </ul>  
                            </div>
                        </article>
                        <article className="f1TopWrapper">
                            <div className="f1TopFlexWrapper">
                                <h3 className="f1Heading">{footerTeamHeading}</h3>
                                <ul className="f1TeamContainer">
                                    <Link to='/holdoversigt/pardans'>Pardans</Link>
                                    <Link to='/holdoversigt/diskohiphop'>Disko/Hip Hop</Link>
                                    <Link to='/holdoversigt/mgpborn'>MGP/Børn</Link>
                                    <Link to='/holdoversigt/dancefitness'>Dancefitness</Link>
                                </ul>
                            </div>
                        </article>
                    </section>

                    <section className="f1BottomContainer">
                        <div className="f1CopyRightText" dangerouslySetInnerHTML={{__html: footerCopyrightText}}></div>
                    </section>
                </section>
            )   
        })}
      </section>
    )
}
