import React, { useState } from 'react';
import darkSquareDesign from '../../assets/designElements/darkSquareDesign2x.svg';
import Slide from './Slide';
import './homeReview.css';

export default function HomeReview({ frontpageData }) {
    const [review, setReview] = useState([frontpageData]);

    return (
        <section id="homeReview">
            {review.map((reviewData, index) => {
                const { reviewHeading, reviews } = reviewData;
                return(
                    <section key={index} className="homeReviewContainer">
                        <img src={darkSquareDesign} alt="Design element" />
                        <section className="homeReviewTopWrapper">
                            <h2 className="homeReviewHeading"><div dangerouslySetInnerHTML={{__html: reviewHeading}}></div></h2>
                        </section>
                        <Slide slideData={reviews} />
                    </section>
                )
            })}
        </section>
    )
}
