import emailjs from '@emailjs/browser';
import React, { useEffect, useRef, useState } from 'react';
import checkedIcon from './assets/checkicon.png';

import { useForm } from 'react-hook-form';

import { BsCheckLg } from 'react-icons/bs';
import { GrMail } from 'react-icons/gr';
import { MdError } from 'react-icons/md';

export default function SignupForm({introText, checkbosOneText, checkbosTwoText, submitTbnText, resetbtnText, teamName}) {
    const formRef = useRef();
    const form = useForm();
    const { register, handleSubmit, formState, getValues, reset } = form;
    const { errors, isSubmitSuccessful } = formState;

    const [teamname, setTeamname] = useState(teamName);
    const [success, setSuccess] = useState(false);
    const [teamSignup, setTeamSignup] = useState(true);
    const [partnerSignup, setPartnerSignup] = useState(true);

    const [trialHourCBValue, setTrialHourCBValue] = useState(false);
    const [newsletterCBValue, setNewsletterCBValue] = useState(true);

    const sendEmail = () => {
        const serviceKey = 'service_a1g59rn';
        const publicKey = '-RcJ-p5Sbuzu7nWZc';
        let templateKey = '';
        let confirmTemplateKey = '';
    
        if(teamSignup && partnerSignup) {
          templateKey = 'template_5qrfivn';
          confirmTemplateKey = 'template_e0zl1zm';
        }
    
        if(teamSignup && !partnerSignup) {
          templateKey = 'template_4q6n0na';
          confirmTemplateKey = 'template_0c7eqqs';
        }
    
        if(!teamSignup) {
          templateKey = 'template_36fukcd';
          confirmTemplateKey = 'template_em1vh7d';
        }
    
        emailjs.sendForm(
          serviceKey, 
          templateKey, 
          formRef.current, 
          publicKey)
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
    
          emailjs.sendForm(
            serviceKey, 
            confirmTemplateKey, 
            formRef.current, 
            publicKey)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
          setTrialHourCBValue(false);
          setNewsletterCBValue(true);
      };
    
      const onSubmt = () => {
        sendEmail();
        setSuccess(true);
      }
    
      const resetForm = () => {
        setTrialHourCBValue(false);
        setNewsletterCBValue(true);
        reset();
      }
    
      useEffect(() => {
        if(isSubmitSuccessful) {
          reset();
        }
      }, [isSubmitSuccessful, reset])
    
      const handleTrialHourChange = () => {
        setTrialHourCBValue(current => !current);
      }
    
      const handleNewsletterChange = () => {
        setNewsletterCBValue(current => !current);
      }
    
      const handleTeamSignupSwitchChange = () => {
        setTeamSignup(true);
      }
    
      const handleNewsletterSwitchChange = () => {
        setTeamSignup(false);
      }
    
      const handlePartnerSwitchChange = () => {
        setPartnerSignup(true);
      }
    
      const handleSoloSwitchChange = () => {
        setPartnerSignup(false);
      }

      function PartnerOrSoloBtns() {
        if(teamSignup) {
          return (
            <div className="signupPartnerOrSoloBtnContainer">
              <div className="signupPartnerOrSoloBtnWrapper">
                <div className={partnerSignup ? 'signupPartnerSignupBtnWrapper active' : 'signupPartnerSignupBtnWrapper'} onClick={handlePartnerSwitchChange}>
                  <a className={partnerSignup ? 'signupPartnerSignupBtn active' : 'signupPartnerSignupBtn'} >Tilmeld Dansepartner</a>
                </div>
                <div className={partnerSignup ? 'signupSoloSignupBtnWrapper' : 'signupSoloSignupBtnWrapper active'} onClick={handleSoloSwitchChange}>
                  <a className={partnerSignup ? 'signupSoloSignupBtn' : 'signupSoloSignupBtn active'} >Tilmeld Som Solo</a>
                </div>
              </div>
            </div>
          )
        } else {
          return <></>
        }
      }

      return (
        <>
          <form className='signupFormWrapper' onSubmit={handleSubmit(onSubmt)} noValidate ref={formRef}>
            <div className="signupIntroText" dangerouslySetInnerHTML={{__html: introText}}></div>
    
            {/* <div className="signupNewsOrTeamSignupBtnContainer">
              <div className="signupNewsOrTeamSignupBtnWrapper">
                <div className={teamSignup ? 'signupTeamSignupBtnWrapper active' : 'signupTeamSignupBtnWrapper'}>
                  <a className={teamSignup ? 'signupTeamSignupBtn active' : 'signupTeamSignupBtn'} >Tilmeld Dansehold</a>
                </div>
                <div className={teamSignup ? 'signupNewsSignupBtnWrapper' : 'signupNewsSignupBtnWrapper active'} onClick={handleNewsletterSwitchChange}>
                  <a className={teamSignup ? 'signupNewsSignupBtn' : 'signupNewsSignupBtn active'} >Tilmeld Nyhedsbrev</a>
                </div>
              </div>
            </div> */}
    
            <div className="signupNewsOrTeamFormContainer">
              {teamSignup ? (
                <div className="signupTeamWrapper">
                  <input type="hidden" name='teamName' value={teamname} />
                  <div className="signupTeamGroupContainer">
                    <div className="cfFormItemContainer">
                      <div className="cfLabelErrorMsgContainer">
                        <label htmlFor='studentFirstname' >Elev fornavn</label>
                        <p className='inputErrorMessage'>{errors.studentFirstname ? <MdError /> : <></>}{errors.studentFirstname?.message}</p>
                      </div>
                      <input type='text' id='studentFirstname' {...register('studentFirstname', {
                        required: {
                          value: true,
                          message: 'Påkrævet',
                        }
                      })} />
                    </div>
                    <div className="cfFormItemContainer">
                      <div className="cfLabelErrorMsgContainer">
                        <label htmlFor='studentLastname' >Elev efternavn</label>
                        <p className='inputErrorMessage'>{errors.studentLastname ? <MdError /> : <></>}{errors.studentLastname?.message}</p>
                      </div>
                      <input type='text' id='studentLastname' {...register('studentLastname', {
                        required: {
                          value: true,
                          message: 'Påkrævet',
                        }
                      })} />
                    </div>
                  </div>
                  <div className="signupTeamGroupContainer">
                    <div className="cfFormItemContainer">
                      <div className="cfLabelErrorMsgContainer">
                        <label htmlFor='studentAddress' >Elev adresse</label>
                        <p className='inputErrorMessage'>{errors.studentAddress ? <MdError /> : <></>}{errors.studentAddress?.message}</p>
                      </div>
                      <input type='text' id='studentAddress' {...register('studentAddress', {
                        required: {
                          value: true,
                          message: 'Påkrævet',
                        }
                      })} />
                    </div>
                    <div className="cfFormItemContainer">
                      <div className="cfLabelErrorMsgContainer">
                        <label htmlFor='studentPhone' >Elev telefon</label>
                        <p className='inputErrorMessage'>{errors.studentPhone ? <MdError /> : <></>}{errors.studentPhone?.message}</p>
                      </div>
                      <input type='text' id='studentPhone' {...register('studentPhone', {
                        pattern: {
                          value: /^[0-9]{8}$/,
                          message: 'ikke gyldigt nummer',
                        },
                        required: {
                          value: true,
                          message: 'Påkrævet',
                        }
                      })} />
                    </div>
                  </div>
                  <div className="signupTeamGroupContainer">
                    <div className="cfFormItemContainer">
                      <div className="cfLabelErrorMsgContainer">
                        <label htmlFor='studentEmail' >Elev email</label>
                        <p className='inputErrorMessage'>{errors.studentEmail ? <MdError /> : <></>}{errors.studentEmail?.message}</p>
                      </div>
                        <input type='email' id='studentEmail' {...register('studentEmail', {
                          pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Ikke godkendt email',
                          },
                          required: {
                            value: true,
                            message: 'Påkrævet',
                          }
                        })} />
                    </div>
                    <div className="cfFormItemContainer">
                      <div className="cfLabelErrorMsgContainer">
                        <label htmlFor='studentEmailControl' >Elev kontrol email</label>
                        <p className='inputErrorMessage'>{errors.studentEmailControl ? <MdError /> : <></>}{errors.studentEmailControl?.message}</p>
                      </div>
                      <input type='email' id='studentEmailControl' {...register('studentEmailControl', {
                        required: {
                          value: true,
                          message: 'Påkrævet',
                        },
                        validate: {
                          confirmEmail: (fieldValue) => {
                            return (
                              fieldValue === getValues('studentEmail') || 'Ikke samme email'
                            )
                          }
                        }
                      })} />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="signupNewsWrapper">
                  <div className="signupNewsGroupContainer">
                    <div className="cfFormItemContainer">
                      <div className="cfLabelErrorMsgContainer">
                        <label htmlFor='newsEmail' >Email</label>
                        <p className='inputErrorMessage'>{errors.newsEmail ? <MdError /> : <></>}{errors.newsEmail?.message}</p>
                      </div>
                      <input type='email' id='newsEmail' {...register('newsEmail', {
                        pattern: {
                          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: 'Ikke godkendt email',
                        }
                      })} />
                    </div>
                    <div className="cfFormItemContainer">
                      <div className="cfLabelErrorMsgContainer">
                        <label htmlFor='newsEmailControl' >Bekræft email</label>
                        <p className='inputErrorMessage'>{errors.newsEmailControl ? <MdError /> : <></>}{errors.newsEmailControl?.message}</p>
                      </div>
                      <input type='email' id='newsEmailControl' {...register('newsEmailControl', {
                        required: {
                          value: true,
                          message: 'Påkrævet',
                        },
                        validate: {
                          confirmEmail: (fieldValue) => {
                            return (
                              fieldValue === getValues('newsEmail') || 'Ikke samme email'
                            )
                          }
                        }
                      })} />
                    </div>
                  </div>
                </div>
              )}
            </div>
    
            <PartnerOrSoloBtns />
    
            {teamSignup ? partnerSignup ? (
              <div className="signupPartnerOsSoloFormContainer">
                <div className="signupPartnerWrapper">
                  <div className="signupPartnerGroupContainer">
                    <div className="cfFormItemContainer">
                      <div className="cfLabelErrorMsgContainer">
                        <label htmlFor='partnerFirstname' >Partner fornavn</label>
                        <p className='inputErrorMessage'>{errors.partnerFirstname ? <MdError /> : <></>}{errors.partnerFirstname?.message}</p>
                      </div>
                      <input type='text' id='partnerFirstname' {...register('partnerFirstname', {
                        required: {
                          value: true,
                          message: 'Påkrævet',
                        }
                      })} />
                    </div>
                    <div className="cfFormItemContainer">
                      <div className="cfLabelErrorMsgContainer">
                        <label htmlFor='partnerLastname' >Partner efternavn</label>
                        <p className='inputErrorMessage'>{errors.partnerLastname ? <MdError /> : <></>}{errors.partnerLastname?.message}</p>
                      </div>
                      <input type='text' id='partnerLastname' {...register('partnerLastname', {
                        required: {
                        value: true,
                        message: 'Påkrævet',
                      }
                      })} />
                    </div>
                  </div>
                  <div className="signupPartnerGroupContainer">
                    <div className="cfFormItemContainer">
                      <div className="cfLabelErrorMsgContainer">
                        <label htmlFor='partnerAddress' >Partner adresse</label>
                        <p className='inputErrorMessage'>{errors.partnerAddress ? <MdError /> : <></>}{errors.partnerAddress?.message}</p>
                      </div>
                      <input type='text' id='partnerAddress' {...register('partnerAddress', {
                        required: {
                          value: true,
                          message: 'Påkrævet',
                        }
                      })} />
                    </div>
                    <div className="cfFormItemContainer">
                      <div className="cfLabelErrorMsgContainer">
                        <label htmlFor='partnerPhone' >Partner telefon</label>
                        <p className='inputErrorMessage'>{errors.partnerPhone ? <MdError /> : <></>}{errors.partnerPhone?.message}</p>
                      </div>
                      <input type='text' id='partnerPhone' {...register('partnerPhone', {
                        pattern: {
                          value: /^[0-9]{8}$/,
                          message: 'ikke gyldigt nummer',
                        },
                        required: {
                          value: true,
                          message: 'Påkrævet',
                        }
                      })} />
                    </div>
                  </div>
                  <div className="signupPartnerGroupContainer">
                    <div className="cfFormItemContainer">
                      <div className="cfLabelErrorMsgContainer">
                        <label htmlFor='partnerEmail' >Partner email</label>
                        <p className='inputErrorMessage'>{errors.partnerEmail ? <MdError /> : <></>}{errors.partnerEmail?.message}</p>
                      </div>
                      <input type='email' id='partnerEmail' {...register('partnerEmail', {
                        pattern: {
                          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: 'Ikke godkendt email',
                        },
                        required: {
                          value: true,
                          message: 'Påkrævet',
                        }
                      })} />
                    </div>
                    <div className="cfFormItemContainer">
                      <div className="cfLabelErrorMsgContainer">
                        <label htmlFor='partnerEmailControl' >Partner kontrol email</label>
                        <p className='inputErrorMessage'>{errors.partnerEmailControl ? <MdError /> : <></>}{errors.partnerEmailControl?.message}</p>
                      </div>
                      <input type='email' id='partnerEmailControl' {...register('partnerEmailControl', {
                        required: {
                          value: true,
                          message: 'Påkrævet',
                        },
                        validate: {
                          confirmEmail: (fieldValue) => {
                            return (
                              fieldValue === getValues('partnerEmail') || 'Ikke samme email'
                            )
                          }
                        }
                      })} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            ) : (
              <></>
            )}
    
            {teamSignup ? (
              <>
                <div className="signupMessageContainer">
                  <div className="cfFormItemContainer">
                    <div className="cfLabelErrorMsgContainer">
                      <label htmlFor='message' >Bemærkninger</label>
                      <p className='inputErrorMessage'>{errors.message ? <MdError /> : <></>}{errors.message?.message}</p>
                    </div>
                    <textarea id='message'></textarea>
                  </div>
                </div>
    
                <div className="signupCheckboxContainer">
                  <div className="signupCheckboxWrapper">
                    <div className="signupCheckboxText" dangerouslySetInnerHTML={{__html: checkbosOneText}}></div>
                    <div className="signupFreeTrialCheckboxContainer" onClick={handleTrialHourChange}>
                      <img src={checkedIcon} alt="checked" className={trialHourCBValue ? '' : 'signupHidden'} />
                      <input type="hidden" name="freeTrial" value={trialHourCBValue ? 'ja' : 'nej'} />
                    </div>
                  </div>
                  <div className="signupCheckboxWrapper">
                    <div className="signupCheckboxText" dangerouslySetInnerHTML={{__html: checkbosTwoText}}></div>
                    <div className="signupNewsletterCheckboxContainer" onClick={handleNewsletterChange}>
                      <img src={checkedIcon} alt="checked" className={newsletterCBValue ? '' : 'signupHidden'} />
                      <input type="hidden" name="newsletter" value={newsletterCBValue ? 'ja' : 'nej'} />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
    
            <div className="signupSubmitBtnContainer">
              {success && (
                <p className="signupFormSuccesMsg"><BsCheckLg /> Du har sendt din tilmelding</p>
              )}
              <div className="signupFormContainerWrapper">
                <button className='signupFormSubmitBtn btn px14 solid roundCorners' ><GrMail /><span>{submitTbnText}</span></button>
                <input className='signupFormSubmitBtn btn px14 trans roundCorners' type='reset' value={resetbtnText} onClick={resetForm} />
              </div>
            </div>
          </form>
        </>
      );
}
