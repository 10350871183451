import React, { useState } from 'react';
import './calenderSection.css';

export default function CalenderSection({ calenderData }) {
    const [calender, setCalender] = useState([calenderData]);

    return (
        <section id="calender">
            {calender.map((calenderData, index) => {
                const { calenderIntroHeading, calenderIntroText, calenderIntroImage, calenderEvents } = calenderData;
                return (
                    <section key={index} className="calenderContainer">
                        <section className="calenderHeroContainer">
                            <article className="calenderHeroTextContainer">
                                <h1 className="calenderHeroHeading"><div dangerouslySetInnerHTML={{__html: calenderIntroHeading}}></div></h1>
                                <div className="calenderHeroText" dangerouslySetInnerHTML={{__html: calenderIntroText}}></div>
                            </article>
                            <section className="calenderHeroImageContainer">
                                <img src={calenderIntroImage} alt="Kalender billede" />
                            </section>
                        </section>
                        <section className="calenderContentContainer">
                            {calenderEvents.map((singleCalenderEvent, index) => {
                                const { calenderEventsHeading, calenderEventsIfVideo, calenderEventsImage, calenderEventsVideo, calenderEventTimePlace, calenderEventsText, calenderEventsBtnText, calenderEventsBtnFile } = singleCalenderEvent;
                                
                                const showImage = (url) => {
                                    return (
                                        <img src={url} alt="Billede" />
                                    )
                                }

                                const showVideo = (url) => {
                                    return (
                                        <video src={url} autoPlay playsInline muted loop >
                                            
                                        </video>
                                    )
                                }

                                const showBtn = () => {
                                    return (
                                        <a href={calenderEventsBtnFile} className="calenderEventProgramBtn btn px14 solid roundCorners" target='_blank' download='Program' rel="noreferrer">{calenderEventsBtnText}</a>
                                    )
                                }
                                
                                return (
                                    <section key={index} className="calenderEventContainer">
                                        <div className="calenderEventImageContainer">
                                            {calenderEventsIfVideo ? showVideo(calenderEventsVideo) : showImage(calenderEventsImage)}
                                        </div>
                                        <article className="calenderEventTextContainer">
                                            <h2 className="calenderEventHeading">{calenderEventsHeading}</h2>
                                            <div className="calenderEventTimePlace" dangerouslySetInnerHTML={{__html: calenderEventTimePlace}}></div>
                                            <div className="calenderEventText" dangerouslySetInnerHTML={{__html: calenderEventsText}}></div>
                                            {calenderEventsBtnFile ? showBtn() : ''}
                                        </article>
                                    </section>
                                )
                            })}
                        </section>
                    </section>
                )
            })}
        </section>
    )
}
