import React, { useState } from 'react';
import ContactUs from './ContactUs';
import './contactform.css';

export default function Contact({ contactData }) {
    const [contact, setContact] = useState([contactData]);

  return (
    <section id='contact'>
        {contact.map((contactData, index) => {
            const { heading, subHeading, cardHeading, cardtext, btnText, contactInfos } = contactData;
            return (
                <section key={index} className='contactContainer'>
                    <h1 className="contactHeading">{heading}</h1>
                    <p className="contactSubheading">{subHeading}</p>
                    <section className='contactFormContainer'>
                        <article className='contactInfoContainer'>
                            <div className="contactInfoTopContainer">
                                <h2 className="contactInfoHeading">{cardHeading}</h2>
                                <div className="contactInfoText" dangerouslySetInnerHTML={{__html: cardtext}}></div>
                            </div>
                            <article className='contactInfoWrapper'>
                                {contactInfos.map((singleContactInfo, index) => {
                                    const { textIcon, text } = singleContactInfo;
                                    return(
                                        <div key={index} className='contactInfoSingleContainer'>
                                            <img className='contactInfoIcon' src={textIcon} alt="Ikon" />
                                            <p className='contactInfoTextloop'>{text}</p>
                                        </div>
                                    )
                                })}
                            </article>
                        </article>
                        <article className='contactFormWrapper'>
                            <ContactUs btnText={btnText} />
                        </article>
                    </section>
                </section>
            )
        })}
    </section>
  )
}
