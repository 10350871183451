import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import Footer from '../footer/Footer';
import SignupForm from './SignupForm';
import './signup.css';

export default function Signup({ signupData, footerData }) {
    const [signup, setSignup] = useState([signupData]);
    const [footer, setFooter] = useState(footerData);
    const location = useLocation();

    return (
        <>
            <section id="signup">
                {signup.map((data, index) => {
                    const {signupIntroText, signupTrialHourText, signupNewsletterText, signupSubmitBtnText, signupResetBtnText } = data;
                    return(
                        <section key={index} className="signupContainer">
                            <h1 className="signupHeading">{location.state}</h1>
                            <SignupForm teamName={location.state} introText={signupIntroText} checkbosOneText={signupTrialHourText} checkbosTwoText={signupNewsletterText} submitTbnText={signupSubmitBtnText} resetbtnText={signupResetBtnText} />
                        </section>
                    )
                })}
            </section>
            <Footer footerData={footer} />
        </>
    )
}
